@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

* {
  font-family: 'Raleway', sans-serif;
}
.gloria-hallelujah-regular {
  font-family: "Gloria Hallelujah", cursive !important;
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}
.ant-col, label:not(.label-default label, .client-details label), .ant-card,.font-large{
  font-size: 20px !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.checkbox-start label.ant-checkbox-wrapper {
  align-items: start !important;
}
.ant-btn-default.bg-sky-800:not(:disabled):hover {
  color: rgb(203 213 225) !important;
  border-color: rgb(3 105 161) !important;
}
.ant-btn-text.text-amber-300:not(:disabled):hover{
  color: white;
}
.ant-btn-text.text-amber-300{
  display: flex;
  align-items: center;
}
.label-w-full .ant-form-item-required{
  width: 100%;
}
.ant-layout-header.roycares-header{
  background: #075985;
}
.ant-layout-footer.roycares-footer{
  padding: 0 !important;
}
.min-content-height{
  min-height: calc(100vh - 192px) !important;
}
.label-style label{
  font-weight:600 !important;
}
.border-l-25{
  border-left-width: 25px;
}
.stress-crds:first-child .border-l-25{
	border-color: #00FF00;
}
.stress-crds:nth-child(2) .border-l-25{
	border-color: #FFF200;
}
.stress-crds:nth-child(3) .border-l-25{
	border-color: #FFA100;
}
.stress-crds:nth-child(4) .border-l-25{
	border-color: #FF0000;
}
.stress-chart text{
	fill:rgb(0, 0, 0) !important;
	font-weight: 600;
}
.ant-image-preview-img-wrapper img{
  display: initial;
}
.bg-pattern{
  background-color: #e7faff;
}
.color-blue{
  color: #1677ff;
}
.color-red{
  color: red;
}
a{
  color: #1677ff;
}
table,.ant-table table, th,.ant-table th, td,.ant-table td {
  border: 2px solid black;
  border-collapse: collapse;
  padding: 15px;
}
.ant-table ,.ant-table tr:hover td,.ant-table th,.ant-table .ant-select-selector {
  background: transparent !important;
}
td.ant-table-cell {
  vertical-align: top;
}
.blue-header th{
  background-color: rgb(2 132 199) !important;
  color: #ffffff !important;
}
.blue-header tr:nth-child(2) th{
  background-color: rgb(14 165 233) !important;
}
.star-ul {
  list-style-type: '✱ ' !important;
}
.flex.table-bordered:last-child {
  border-bottom: 2px solid black;
}
.min-content-height ol{
  margin-left: 20px;
}
.min-content-height ul{
  list-style-position: outside !important;
  margin-left: 20px;
  list-style-type: circle !important;
}
.ant-form-item-label, .ant-checkbox-wrapper{
  font-weight: bold !important;
}
/* editable cell css starts*/
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid #afafaf;
  border-radius: 2px;
  min-height: 33px;
}
.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.blue-header tbody tr td {
  vertical-align: top;
}
[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
.ant-popconfirm-buttons .ant-btn-primary{
  background-color: #005983 !important;
}
.react-tel-input .form-control{
  width: 100% !important;
}
/* editable cell css ends*/
/*welcom modal css*/
.welcome-modal {
  overflow: hidden;
}
.welcome-modal .ant-modal-header::after {
  content: "";
  background: rgb(7 89 133);
  opacity: 0.2;
  width: 100%;
  height: 236px;
  position: absolute;
  left: 0;
  top: -160px;
  border-radius: 50%;
}
/* Digital Signature */
.sigContainer {
  width: 100%;
  height: 50%;
  margin: 0 auto;
  background-color: #fff;
}
.inputNumber .ant-input-number-wrapper .ant-input-number-group-addon{
  font-weight:bold;
}
.sigPad {
  width: 100%;
  height: 100%;
}
.disabled-outline.ant-select-disabled .ant-select-selector {
  border-color: #ff7875;
}
.disabled-dark .ant-checkbox-disabled+span,
.disabled-dark .ant-radio-wrapper-disabled
{
  color: rgba(0, 0, 0, 0.88) !important;
}
.disabled-dark .ant-checkbox-disabled .ant-checkbox-inner:after{
  border-color: #fff !important;
}
.disabled-dark .ant-checkbox-disabled .ant-checkbox-inner,
.disabled-dark .ant-radio-disabled .ant-radio-inner::after{
  background:  #8b9087;
}
.disabled-dark .ant-radio-disabled .ant-radio-inner{
  border-color:  #8b9087;
}
.ant-checkbox-wrapper-checked .ant-checkbox-inner,
.disabled-dark .ant-radio-checked .ant-radio-inner{
  background-color: #1677ff !important;
  border-color: #1677ff !important;
}
.disabled-dark .ant-radio-checked .ant-radio-inner::after{
  background: white;
}
.ant-modal-content .ant-btn-primary, 
.ant-radio-button-checked, 
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){ 
  background-color:#075985 !important;
}
.client-details label {
  font-size: 14px!important;
}
.registration-link-table td {
  padding: 3px;
}
.customForm .ant-form-item-row{
  flex-direction:row !important;
  flex-flow:unset !important;
}
.customForm .ant-form-item-row .ant-form-item-label{
  flex-basis:15% !important;
}
.customForm .ant-form-item-row .ant-form-item-control{
  flex-basis:80% !important;
}
.select-item-bold .ant-select-selector .ant-select-selection-item{
  font-weight: bold;
}
.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ffedae !important;
  background: #fef7e4 !important;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:e-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  background: transparent !important;
  width: 85% !important;
  left: 0 !important;
}
.flexible-modal-mask{
  display: none;
}
.notes-modal .notes-header{
  border-bottom: 2px solid #ffedae;
  background-color: #fbf2d5;
  padding: 17px;
  border-radius: 2px 2px 0 0;
  height: 50px;
}
.notes-header h3{
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.notes-modal::placeholder {
	color: #C0B481;
  font-weight: 700 !important;
}
.iframe-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Set the height to the full viewport height */
  overflow: hidden;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.anticon svg{
   vertical-align: unset !important;
}
.ant-table-placeholder {
  background: transparent !important;
  color: black !important; 
}
.custom-form-label .ant-form-item-label > label::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*";
}
.custom-ant-empty-image .ant-empty-image{
  height: 10px;
}
.ant-table-wrapper .ant-table,
.ant-form-item .ant-form-item-label >label 
{
  color: #000000 !important;
}
.admin-notes .ql-container.ql-snow{
  min-height: 271px;
}
.flexible-modal-drag-area-right, 
.flexible-modal-drag-area-left
{
  height:unset !important;
}
.ant-form-vertical .ant-form-item-row {
  flex-direction: initial !important;
}
.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
  border-color: #ff4d4f !important;
}
.react-tel-input{
  border-radius: 5px;
  border-color: rgb(255, 77, 79) !important;
}
.ant-checkbox-wrapper{
  align-items: center !important;
}
.ql-editor{
  font-size: 1.5em !important;
}
.label-fs-xs label{
  font-size: 0.875rem !important;
}

.form-mb-0 .ant-form-item{
  margin-bottom: 0 !important;
}
.form-mb-0 .ant-form-item-label{
  padding-bottom: 2px !important;
}
.disabled-select-dark .ant-select-selector .ant-select-selection-item{
  color:#000000;
}
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
  border-inline-end: 1px solid #000000;
}
th.ant-table-cell.ant-table-cell-scrollbar {
  border: none;
}
.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper .ant-table-tbody>tr>th
 {
  border-bottom: 1px solid #000000;
}
.borderTable.ant-table-wrapper .ant-table-thead >tr>th{
  border-bottom: 1px solid #000000;
}
.collapseTable.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: initial;
}
#downloadContent .ant-checkbox,
#downloadContent .ant-radio,
#group1 .ant-checkbox,
#group2 .ant-checkbox,
#group3 .ant-checkbox,
#group1 .ant-radio,
#group2 .ant-radio,
#group3 .ant-radio {
  top: 0.5em !important;
}
#downloadContent ul,
#downloadContent ol,
#group1 ul,
#group2 ul,
#group3 ul,
#group1 ol,
#group2 ol,
#group3 ol {
  margin-left: 20px;
}
#downloadContent ul,
#group1 ul,
#group2 ul,
#group3 ul {
  list-style-type: circle;
}
#downloadContent b ul,
#group1 b ul,
#group2 b ul,
#group3 b ul {
  list-style-type: none;
}
#downloadContent u b,
#downloadContent u strong,
#downloadContent u span,
#downloadContent u,
#group1 u,
#group2 u,
#group3 u,
#group1 u b,
#group2 u b,
#group3 u b,
#group1 u strong,
#group2 u strong,
#group3 u strong,
#group1 u span,
#group2 u span,
#group3 u span {
  text-decoration: underline !important;
}
#downloadContent .ant-col,
label:not(.label-default label, .client-details label),
.ant-card {
  font-size: 16px !important;
}
.labelInput .ant-form-item .ant-form-item-label>label::after {
  content: "";
}
.labelCenter .ant-col.ant-form-item-label{
  display: table;
}
.labelCenter .ant-row.ant-form-item-row {
  align-items: center;
}
.labelInput .ant-form-item.mb-0 .ant-row.ant-form-item-row {
  flex-direction: column;
}
.labelInput .ant-form-item.mb-0 .ant-row.ant-form-item-row .ant-col.ant-form-item-label {
  align-self: flex-start;
}
.customTable th, .customTable td{
  padding: 15px 2px;
}
.customTable tr td {
  width: 14%;
}
.customCollapse.ant-collapse, .tableCollapse.ant-collapse, .customCollapse.ant-collapse .ant-collapse-item, .customCollapse.ant-collapse> .ant-collapse-item:last-child> .ant-collapse-header, .customCollapse.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 !important;
  border: 0 !important;
}
.customCollapse.ant-collapse .ant-collapse-content-box{
  border-width: 1px;
}
.customCollapse.ant-collapse, .tableCollapse.ant-collapse{
  background-color: #ffffff;
}
.customCollapse.ant-collapse .ant-collapse-header {
  background-color: #fcd34d !important;
}
.tableCollapse.ant-collapse .ant-collapse-header {
  background-color: #ffffff!important;
  border: 1px solid #d9d9d9;
  border-radius: 0 !important;
}
.tableCollapse.ant-collapse .ant-collapse-content-box{
  border-top-width: 0 !important;
}
.dob-mb-0 .ant-form-item {
  margin-bottom: 0 !important;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #d9d9d9 !important;
}
span.ant-radio-button.ant-radio-button-checked {
  border-radius: inherit;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background: none;
}
.ant-btn,
.ant-radio-button-wrapper span {
  font-size: 12px !important;
}
.ant-input-disabled,
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selection-placeholder,
.ant-select-disabled .ant-select-selection-item .ant-select-selection-item-content,
.ant-select-disabled .ant-select-selection-item {
  color: #000000db !important;
}
.ant-input-disabled,
.ant-input-number-disabled {
  font-weight: 500 !important;
}
.ant-select-disabled .ant-select-selection-placeholder,
.ant-select-disabled .ant-select-selection-item .ant-select-selection-item-content,
.ant-select-disabled .ant-select-selection-item {
  font-weight: 600 !important;
}
.repeatData {
  display: initial;
}
.borderNoneTable table,
.borderNoneTable td {
  border: none !important;
  border-inline-end: none !important;
}
.iconBackground {
  background-color: #000000;
  display: inline-block;
  padding: 8px;
  color: #ffffff;
  border-radius: 100%;
}
.iconBackground svg {
  width: 1.5em;
  height: 1.5em;
}
.borderNoneTable table td:nth-child(1),
.borderNoneTable table td:nth-child(2),
.borderNoneTable table td:nth-child(3) {
  border-right: 2px dashed #000000 !important;
}
#pdfContent .html2pdf__page-break::before {
  content: "Download this outline & cut through the dotted lines to create your own Change list.";
  font-size: 12px;
  margin-top: -7px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.form-end .ant-row {
  justify-content: end;
}
.add-note .ant-form-inline .ant-form-item {
  margin-inline-end: 0;
}
.add-note .ant-select-selection-placeholder {
  color: #6b7280;
}
.ant-note .ant-select-selection-item,
.add-note .ant-input,
.add-note .ant-select-selection-item-content,
.add-note .ant-select-selection-item {
  color: #000000;
}